var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:_vm.isBlock ? 'block' : '',on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("front-icon"),_vm._v(" "),(_vm.isInternalLink)?_c('simple-link',{staticClass:"link",class:{
      'block' : _vm.isBlock,
      'link--primary': _vm.type === _vm.LinkType.Primary,
      'link--no-underline': _vm.type === _vm.LinkType.NoUnderline,
      'link--secondary': _vm.type === _vm.LinkType.Secondary,
      'link--dark-no-underline': _vm.type === _vm.LinkType.Dark,
      'link--no-style': _vm.type === _vm.LinkType.NoStyle,
      'link--inherit-color': _vm.type === _vm.LinkType.InheritColor
    },attrs:{"to":_vm.shouldPushUrl ? _vm.url : ("/" + _vm.url),"target":_vm.blank ? '_blank' : '_self'}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.text))]})],2):_c('ExternalLink',{attrs:{"url":_vm.url,"text":_vm.text}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.text))]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }