








import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { getUrlWithResizeQuery } from '~/helpers/twicpics/getUrlWithResizeQuery';
import { SCHEMA_ITEM_PROP } from '~/constants/seo';
import { TwicpicsMode, TWICPICS_MODE } from '~/types/twicpics/twicpics';

export default defineComponent({
  name: 'TwicpicsImage',
  components: {
    ImgView
  },
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    mode: {
      type: String as PropType<TwicpicsMode>,
      default: TWICPICS_MODE.CONTAIN
    }
  },
  setup(props) {
    const imageUrl = computed(() => getUrlWithResizeQuery(props.src, {
      mode: props.mode,
      width: props.width,
      height: props.height
    }));

    return {
      imageUrl,
      SCHEMA_ITEM_PROP
    };
  }
});

