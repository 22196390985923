














































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useI18n } from '~/composables/useI18n';
import { useRouteExtended } from '~/composables';
import { ROUTES } from '~/constants/routes';
import { SCHEMA_ITEM_PROP } from '~/constants/seo';

export default defineComponent({
  name: 'ProductCardPricing',
  props: {
    price: {
      type: Number,
      required: true
    },
    priceBeforeDiscounts: {
      type: Number,
      default: null
    },
    shouldDisplayDiscount: {
      type: Boolean,
      default: true
    },
    qty: {
      type: Number,
      default: 1
    },
    showTotalPrice: {
      type: Boolean,
      default: false
    },
    hideStrikethroughPrice: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false
    },
    isActiveProduct: {
      type: Boolean,
      default: false
    },
    isVerticalPrice: {
      type: Boolean,
      default: false
    },
    isPriceCentered: {
      type: Boolean,
      default: true
    },
    reversePrices: {
      type: Boolean,
      default: false
    },
    smallTextPrices: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root: { $route } }) {
    const { countryLocalization } = useI18n();
    const { getAdjustedSlug } = useRouteExtended();
    const { i18n } = useVSFContext();
    const priceCurrency = computed(() => countryLocalization.value.currencyCode);

    const currentPrice = computed(() => {
      if (props.priceBeforeDiscounts !== null) {
        // on ThankYou page, total price of each item should be displayed
        return props.showTotalPrice ? props.priceBeforeDiscounts * props.qty : props.priceBeforeDiscounts;
      } else {
        return props.showTotalPrice ? props.price * props.qty : props.price;
      }
    });
    const oldPrice = computed(() => (props.priceBeforeDiscounts !== null ? props.price : null));
    const isCart = computed(() => $route.path === getAdjustedSlug(ROUTES.CART));
    const isDiscountDisplayed = computed(() => oldPrice.value && props.shouldDisplayDiscount);

    const primaryPrice = computed(() => (props.reversePrices && isDiscountDisplayed.value)
      ? i18n.n(oldPrice.value, 'currency')
      : i18n.n(currentPrice.value, 'currency'));
    const secondaryPrice = computed(() => props.reversePrices
      ? i18n.n(currentPrice.value, 'currency')
      : i18n.n(oldPrice.value, 'currency'));

    return {
      currentPrice,
      oldPrice,
      isCart,
      SCHEMA_ITEM_PROP,
      priceCurrency,
      isDiscountDisplayed,
      primaryPrice,
      secondaryPrice
    };
  }
});
