import { render, staticRenderFns } from "./ProductCardPricing.vue?vue&type=template&id=c79ee84c&scoped=true&"
import script from "./ProductCardPricing.vue?vue&type=script&lang=ts&"
export * from "./ProductCardPricing.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCardPricing.vue?vue&type=style&index=0&id=c79ee84c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79ee84c",
  null
  
)

export default component.exports