export const PRODUCT_CARD = {
  IMAGE_HEIGHT: 300,
  IMAGE_WIDTH: 320
} as const;

export const VERTICAL_PRODUCT_CARD = {
  IMAGE_HEIGHT: 100,
  IMAGE_WIDTH: 100
} as const;

export const SIMPLIFIED_CARD = {
  IMAGE_HEIGHT: 120,
  IMAGE_WIDTH: 120
} as const;
